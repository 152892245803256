import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import TreeTable from "components/tree/TreeTable";
import { TableMapping } from "./data/TableMapping";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { transformData } from "helpers/transformData";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import React, { useCallback, useEffect, useState } from "react";

export const CategoryTable = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: customer, remove } = UserData();
  const uri = "category/tree?user_id=" + (customer?.data?.id || "");
  const [treeData, setTreeData] = useState();
  const [oldData, setOldData] = useState();
  const [goto, setGoto] = useState();
  const title = t("Categories");
  const createButton = (
    <Link
      key="/admin/category/create"
      to="/admin/category/create"
      className="btn btn-primary"
    >
      Create
    </Link>
  );

  const getAction = useCallback(
    (deleteItem) => ({
      Images: {
        url: "/admin/category/image",
        addId: true,
      },
      Show: {
        url: "/admin/category",
        addId: true,
      },
      Edit: {
        url: "/admin/category/edit",
        addId: true,
      },
      Delete: {
        callback: deleteItem,
      },
    }),
    []
  );

  const fetchData = useCallback(
    async (deleteItem) => {
      return await apiRequestUri(uri)
        .then((response) => {
          let responseData = transformData(
            response.data,
            TableMapping,
            t,
            customer
          );
          setTreeData(
            <TreeTable data={responseData} action={getAction(deleteItem)} />
          );
          setOldData(responseData);
        })
        .catch((error) => {
          remove();
          appLogout().then(() => {
            setGoto("/login");
          });
        });
    },
    [uri, getAction, remove, t]
  );

  const deleteItem = useCallback(
    (id) => {
      const swalElement = Swal.fire({
        icon: "error",
        title: "Deleting ...",
        showConfirmButton: false,
      });
      let uri = "category/" + id;
      apiRequestUri(uri, "DELETE", {}, false)
        .then((response) => {
          swalElement.close();
          if (response.error === "The Category has Products.") {
            Swal.fire({
              icon: "warning",
              title: response.error,
              text: t(
                "Do you want to delete the category and all its products?"
              ),
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: t("Yes"),
              cancelButtonText: t("No"),
            }).then((result) => {
              if (result.isConfirmed) {
                let uri = "category/" + id + "?products=true";
                apiRequestUri(uri, "DELETE", {}, false).then((response) => {
                  Swal.fire({
                    icon: "warning",
                    title: t("Category deleted successfully."),
                    showConfirmButton: false,
                    timer: closeTime,
                    timerProgressBar: true,
                  }).then(() => {
                    setTreeData();
                    fetchData(deleteItem);
                  });
                });
              }
            });
            return;
          }
          setTreeData();
          fetchData(deleteItem);
        })
        .catch((error) => {
          remove();
          appLogout().then(() => {
            setGoto("/login");
          });
        });
    },
    [remove, closeTime, fetchData, t]
  );

  const filterData = (data, event) => {
    let searchValues = {};
    if (event.target.value === "") return data;
    let newData = data.filter((row) => {
      for (const key in TableMapping) {
        const element = TableMapping[key].property;
        if (row[element]) {
          searchValues[element] = row[element]
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        } else {
          searchValues[element] = null;
        }
      }
      for (const key in TableMapping) {
        const element = TableMapping[key].property;
        if (searchValues[element]) {
          return searchValues[element];
        }
      }
      return false;
    });
    for (const element of data) {
      if (Array.isArray(element.children) && element.children.length > 0) {
        newData = newData.concat(filterData(element.children, event));
      }
    }
    return newData;
  };

  const handleSearch = (e) => {
    const newData = filterData(oldData, e);
    setTreeData(<TreeTable data={newData} action={getAction(deleteItem)} />);
  };

  useEffect(() => {
    if (!treeData) {
      fetchData(deleteItem);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [treeData, fetchData, goto, navigate, deleteItem]);

  if (!customer) {
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
    return;
  }

  return (
    <Container className="mt-0 mb-5 text-center mx-0 mw-100">
      {treeData ? (
        <Container className="mw-100">
          <Row>
            <Col>
              <h4>{title}</h4>
            </Col>
            <Col className="text-end">
              <input
                id="searchInput"
                type="search"
                className="form-control-sm border ps-3 m-3"
                placeholder={t("Search")}
                onChange={handleSearch}
              />
              {createButton}
            </Col>
          </Row>
          {treeData}
        </Container>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};

export default CategoryTable;
