import { Table } from "components/Table";
import { appLogout } from "helpers/helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { ClientView } from "views/client/Client";
import { Card, Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { TableMapping } from "views/client/purchase/data/TableMapping";

const Purchase = ({ customer, customerPath }) => {
  const backgroundColor =
    customer && customer.data ? customer.data.style.cardcolor : "white";
  const { t } = useTranslation();
  const { data: user, remove } = UserData();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  const uri = "order?customer_id=" + customer.id + "&user_id=" + user?.data?.id;
  const action = {
    Show: {
      url: "/" + customerPath + "/purchase",
      addId: true,
    },
  };
  const content = (
    <Card className="m-5" style={{ backgroundColor: backgroundColor }}>
      <Table
        backgroundColor={backgroundColor}
        className="mt-5"
        title={t("Purchases")}
        mapping={TableMapping}
        action={action}
        uri={uri}
        user={user}
      />
    </Card>
  );

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  if (!customer) {
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
    return;
  }

  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Container className="text-center">{content}</Container>
    </ClientView>
  );
};

export default Purchase;
