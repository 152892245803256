import Swal from "sweetalert2";
import Table from "components/Table";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import React, { useEffect, useState } from "react";
import { TableMapping } from "./data/TableMapping";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

export const CategoryImageTable = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  let { id } = useParams();
  const { t } = useTranslation();
  const { data: user, remove } = UserData();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  let uri = "category/" + id + "/image";
  const actionButton = (event) => {
    const file = event.target.files[0];
    setContent(<LoadingSpinner />);
    let uri = "category/" + id + "/image";
    let formData = new FormData();
    formData.append("image", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      },
    };
    apiRequestUri(uri, "POST", formData, config)
      .then((response) => {
        setContent(tableContent);
      })
      .catch((error) => {
        setContent(tableContent);
        Swal.fire({
          icon: "error",
          title: t("Cannot upload file."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
      });
  };
  const addButton = (
    <form className="d-inline">
      <label htmlFor="files" className="btn btn-primary">
        {t("Add Image")}
      </label>
      <input
        id="files"
        className="d-none"
        type="file"
        accept="image/*"
        onChange={actionButton}
      />
    </form>
  );
  const reorderAction = (object, direction, actualTableData, setTableData) => {
    let uri = "category/" + id + "/image/" + object.id;
    let position = object.position + 1;
    if (direction === "up") {
      position = object.position - 1;
    }
    let data = {
      position: position,
    };
    let toReplace;
    for (let item of actualTableData) {
      if (item.position === position) {
        toReplace = item;
      }
    }
    if (toReplace) {
      let newData = [];
      for (let item of actualTableData) {
        if (item.id !== toReplace.id && item.id !== object.id) {
          newData.push(item);
        }
      }
      toReplace["position"] = object.position;
      newData.push(toReplace);
      object["position"] = position;
      newData.push(object);
      newData.sort((a, b) => a.position - b.position);
      setTableData(newData);
      apiRequestUri(uri, "PUT", data, false)
        .then((response) => {
          // setContent(tableContent);
        })
        .catch((error) => {
          setContent(<LoadingSpinner />);
          Swal.fire({
            icon: "error",
            title: t("An error has occurred."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then((result) => {
            setContent(tableContent);
          });
        });
    }
  };
  const deleteItem = (itemId) => {
    setContent(<LoadingSpinner />);
    const swalElement = Swal.fire({
      icon: "error",
      title: t("Deleting ..."),
      showConfirmButton: false,
    });
    let uri = "category/" + id + "/image/" + itemId;
    apiRequestUri(uri, "DELETE", {}, false)
      .then((response) => {
        swalElement.close();
        setContent(tableContent);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          swalElement.close();
          setContent(tableContent);
        } else {
          remove();
          appLogout().then(() => {
            setGoto("/login");
          });
        }
      });
  };
  let action = {
    Delete: {
      callback: deleteItem,
    },
  };
  const tableContent = (
    <Table
      title={t("Category Images")}
      mapping={TableMapping}
      titleElement={addButton}
      action={action}
      uri={uri}
      reorderRows={reorderAction}
      user={user}
    />
  );
  const [content, setContent] = useState(tableContent);

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <Container className="my-5 text-center mx-0 mw-100">{content}</Container>
  );
};

export default CategoryImageTable;
