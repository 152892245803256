import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment-timezone";
import { Rating } from "@mui/material";
import { useForm } from "hooks/useForm";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";

export const AdminProfile = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { data: user, remove, refetch } = UserData();
  const { t } = useTranslation();
  const timezones = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz.replace(/\//g, " / "),
  }));

  let target = useMemo(
    () => ({
      method: "PUT",
      uri: "user/" + (user ? user.data.id : ""),
    }),
    [user]
  );

  const resultado = timezones.find(
    (tz) => tz.value === user?.data?.data?.timezone
  );

  const { formState, onInputChange } = useForm({
    timezone: resultado ? resultado.value : "UTC",
  });
  const [selectedTimezone, setSelectedTimezone] = useState(
    resultado
      ? resultado
      : {
          value: "UTC",
          label: "UTC",
        }
  );
  const defaultButton = <Button as="input" type="submit" value={t("Update")} />;
  const [currentButton, setButton] = useState(defaultButton);
  const [goto, setGoto] = useState();
  const [loaded, setLoaded] = useState();

  const navigate = useNavigate();

  const handleShow = useCallback(() => {
    Swal.fire({
      icon: "error",
      title: t("An error has occurred."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    }).then((result) => {
      remove();
      appLogout().then(() => {
        setGoto("/login");
      });
    });
  }, [remove, closeTime, t]);

  const handleChange = (event) => {
    setSelectedTimezone(event);
    const target = {
      target: {
        name: "timezone",
        value: event.value,
      },
    };
    onInputChange(target);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setButton(<LoadingSpinner />);
    refetch().then((response) => {
      if (!response || !response.data) {
        handleShow();
        return;
      }
      let newData = response.data.data.data;
      if (!newData) {
        newData = {};
      }
      if (!Object.hasOwnProperty.call(newData, "timezone")) {
        newData["timezone"] = [];
      }
      newData.timezone = formState.timezone;
      apiRequestUri(target.uri, target.method, { data: newData }, false)
        .then(() => {
          refetch().then(() => {
            Swal.fire({
              icon: "success",
              title: t("Timezone updated successfully."),
              showConfirmButton: false,
              timer: closeTime,
              timerProgressBar: true,
            });
          });
          setButton(defaultButton);
        })
        .catch((error) => {
          handleShow();
        });
    });
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
    if (resultado && !loaded) {
      setSelectedTimezone(resultado);
      handleChange(resultado);
      setLoaded(true);
    }
  }, [goto, resultado]);

  return (
    <>
      <Card className="my-5 w-50 mx-auto">
        <Card.Body className="text-start">
          <Card.Title>
            <Row>
              <Col>{t("User Profile")}</Col>
              <Col className="text-end">
                {user?.data?.validated === 1 ? (
                  <>
                    {t("Verified")}{" "}
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="mx-2 text-primary"
                    />
                  </>
                ) : (
                  <>
                    {t("Not Verified")}{" "}
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className="mx-2 text-danger"
                    />
                    <Link to="/admin/verify" className="btn btn-primary">
                      {t("Verify")}
                    </Link>
                  </>
                )}
              </Col>
            </Row>
          </Card.Title>
          <hr />
          <Card.Text>
            <Row>
              <Col>
                <strong>{t("Rating")}:&nbsp;</strong>
                <span>
                  {Number(user?.data?.rating)}/5&nbsp;
                  <Rating
                    readOnly
                    defaultValue={user?.data?.rating}
                    size="large"
                  />
                </span>
                <br />
                <strong>{t("Visitors")}:&nbsp;</strong>{" "}
                {user?.data?.data?.visitors?.length ?? 0}
                <div className="py-3 mt-1"></div>
                <strong>{t("Name")}:&nbsp;</strong> {user?.data?.name}
                <br />
                <strong>{t("Email")}:&nbsp;</strong>
                {user?.data?.email}
              </Col>
              <Col className="text-end">
                <Link
                  className="btn btn-warning mb-3"
                  to="/admin/password/change"
                >
                  {t("Change password")}
                </Link>
                <br />
                <Link className="btn btn-success mb-3" to="/admin/complain">
                  {t("Complain")}
                </Link>
                <br />
                <Link className="btn btn-secondary" to="/admin/name">
                  {t("Change Name")}
                </Link>
              </Col>
            </Row>
          </Card.Text>
          <hr />
          <form onSubmit={onSubmit}>
            <Row className="form-group">
              <label htmlFor="timezone" className="mb-1 fw-bold">
                Selecciona una zona horaria:
              </label>
              <Col>
                <Select
                  id="timezone"
                  className="basic-single"
                  options={timezones}
                  value={selectedTimezone}
                  onChange={handleChange}
                  isSearchable
                  placeholder="Escribe para buscar..."
                />
              </Col>
              <Col>{currentButton}</Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </>
  );
};
