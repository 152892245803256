import { Table } from "components/Table";
import { appLogout } from "helpers/helper";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import React, { useEffect, useState } from "react";
import { TableMapping } from "./data/TableMapping";

export const PaymentTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: customer, remove } = UserData();
  const [goto, setGoto] = useState();
  let uri = "platform_payment";

  let action = {
    Documents: {
      url: "/admin/payment/document",
      addId: true,
    },
    Show: {
      url: "/admin/payment",
      addId: true,
    },
    Edit: {
      url: "/admin/payment/edit",
      addId: true,
    },
  };
  const tableContent = (
    <Table
      title={t("Payments")}
      mapping={TableMapping}
      action={action}
      uri={uri}
      user={customer}
    />
  );
  if (
    customer &&
    customer.data &&
    customer.data.role_name &&
    customer.data.role_name !== "admin"
  ) {
    setGoto("/admin");
  }
  if (!customer || !customer.data) {
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
  }

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <Container className="mt-0 mb-5 text-center mx-0 mw-100">
      {tableContent}
    </Container>
  );
};

export default PaymentTable;
