import Swal from "sweetalert2";
import { Table } from "components/Table";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import React, { useEffect, useState } from "react";
import { TableMapping } from "./data/TableMapping";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

export const QuestionTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: customer, remove } = UserData();
  const [goto, setGoto] = useState();
  let uri = "product/question";

  const deleteItem = (id) => {
    setContent(<LoadingSpinner />);
    const swalElement = Swal.fire({
      icon: "error",
      title: t("Deleting ..."),
      showConfirmButton: false,
    });
    let uri = "product/question/" + id;
    apiRequestUri(uri, "DELETE", {}, false)
      .then((response) => {
        swalElement.close();
        setContent(tableContent);
      })
      .catch((error) => {
        remove();
        appLogout().then(() => {
          setGoto("/login");
        });
      });
  };
  let action = {
    Show: {
      url: "/admin/question",
      addId: true,
    },
    Answer: {
      url: "/admin/question/edit",
      addId: true,
    },
    Delete: {
      callback: deleteItem,
    },
  };
  const tableContent = (
    <Table
      title={t("Questions")}
      mapping={TableMapping}
      action={action}
      uri={uri}
      user={customer}
    />
  );
  const [content, setContent] = useState(tableContent);

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  if (!customer || !customer.data) {
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
  }
  return (
    <Container className="mt-0 mb-5 text-center mx-0 mw-100">
      {content}
    </Container>
  );
};

export default QuestionTable;
