import {
  Col,
  Row,
  Dropdown,
  Container,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "../LoadingSpinner";
import { transformData } from "helpers/transformData";
import React, { useCallback, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Products } from "helpers/ClientHelper";

export const NewTable = (props) => {
  let mapping = props.mapping;
  let title = props.title;
  let action = props.action;
  const customerId = props.customerId;
  const className = props.className;
  const backgroundColor = props.backgroundColor;
  let initialPerPage = 10;
  let titleElement = [];
  const reorderRows = props.reorderRows;
  if (props.perPage) initialPerPage = props.perPage;
  if (props.titleElement) titleElement = props.titleElement;
  const user = props.user;

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [initialFetch, setInitialFetch] = useState(true);
  const [search, setSearch] = useState();
  const [showData, setShowData] = useState();
  const [sortField, setSortField] = useState("id");
  const [direction, setDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  const { t } = useTranslation();

  const { data, isFetching, isLoading, isRefetching, refetch } = Products(
    customerId,
    currentPage,
    perPage,
    sortField,
    direction,
    search
  );

  createTheme(
    "customized",
    {
      text: {
        primary: "black",
        secondary: "black",
      },
      button: {
        default: "black",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(0, 0, 0, .34)",
      },
      background: {
        default: backgroundColor,
      },
    },
    "dark"
  );

  const actionButton = (object, button) => {
    if (button.callback) {
      button.callback(object.id, object);
    } else {
      if (!button || !button.url) {
        return;
      }
      let url = button.url;
      if (button.addId) {
        url += "/" + object.id;
      }
      setGoto(url);
    }
    return;
  };

  let columns = [];
  if (mapping) {
    for (const key in mapping) {
      columns.push({
        name: t(key),
        selector: (row) => row[mapping[key].property],
        sortField: mapping[key].property,
        sortable: mapping[key].sortable,
        minWidth: "160px",
      });
    }
    if (action) {
      const actionKeys = Object.keys(action);
      if (actionKeys.length === 1) {
        columns.push({
          cell: (object) => {
            let x = Math.floor(Math.random() * 10000 + 1);
            return (
              <Button
                key={x}
                onClick={(e) => actionButton(object, action[actionKeys[0]])}
              >
                {" "}
                {t(actionKeys[0])}{" "}
              </Button>
            );
          },
        });
      } else {
        columns.push({
          cell: (object) => {
            let actionButtons = [];
            for (const key in action) {
              const element = action[key];
              let x = Math.floor(Math.random() * 10000 + 1);
              actionButtons.push(
                <Dropdown.Item
                  key={key + x}
                  onClick={(e) => actionButton(object, element)}
                >
                  {" "}
                  {t(key)}{" "}
                </Dropdown.Item>
              );
            }
            return (
              <Dropdown data-tag="allowRowEvents">
                <Dropdown.Toggle variant="warning">
                  {" "}
                  {t("Action")}{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className="position-fixed">
                  {actionButtons}
                </Dropdown.Menu>
              </Dropdown>
            );
          },
        });
      }
    }
  }

  if (reorderRows) {
    columns.push({
      cell: (object) => {
        return (
          <ButtonGroup>
            <Button
              size="sm"
              variant="outline-info"
              onClick={(e) => reorderRows(object, "up", data, refetch)}
            >
              ▲
            </Button>
            <Button
              size="sm"
              variant="outline-info"
              onClick={(e) => reorderRows(object, "down", data, refetch)}
            >
              ▼
            </Button>
          </ButtonGroup>
        );
      },
    });
  }

  const onRowClicked = (row, event) => {
    actionButton(event, row.id);
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    const search = e.target.value.toLowerCase();
    setSearch(search && search !== "" ? search : null);
  };

  const fetchData = useCallback(
    async (page) => {
      return await refetch();
    },
    [refetch]
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = async (column, sortDirection) => {
    setSortField(column.sortField ? column.sortField : "id");
    setDirection(sortDirection ? sortDirection : "asc");
  };

  useEffect(() => {
    if (initialFetch) {
      fetchData(1);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
    if (!data && customerId) refetch();
    if (data) {
      const responseData = transformData(data.data, mapping, t, user);
      setShowData(responseData);
      setTotalRows(data.meta?.total);
      setInitialFetch(false);
    }
  }, [customerId, data, fetchData, initialFetch, goto, navigate]);

  return (
    <Container
      className={"mw-100 " + className}
      style={{ backgroundColor: backgroundColor }}
    >
      <Row>
        <Col>
          <h4>{title}</h4>
        </Col>
        <Col className="text-end">
          <input
            id="searchInput"
            type="search"
            className="form-control-sm border ps-3 m-3"
            placeholder={t("Search")}
            onChange={handleSearch}
            onLoad={handleSearch}
          />
          {titleElement}
        </Col>
      </Row>
      <DataTable
        theme={backgroundColor ? "customized" : ""}
        columns={columns}
        data={showData}
        onRowClicked={onRowClicked}
        progressPending={isLoading || isFetching || isRefetching}
        progressComponent={<LoadingSpinner />}
        dense
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        sortServer
        // selectableRows
      />
    </Container>
  );
};

export default NewTable;
