import Table from "components/Table";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import React, { useEffect, useState } from "react";
import { TableMapping } from "./data/TableMapping";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

export const UserComplainTable = () => {
  const { data: customer, remove } = UserData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [goto, setGoto] = useState();
  const uri = "user/complain?user_id=" + id;
  const markAsRead = (id) => {
    setContent(<LoadingSpinner />);
    let uri = "user/complain/" + id;
    const formData = {
      readed: 1,
    };
    apiRequestUri(uri, "PUT", formData)
      .then((response) => {
        setContent(tableContent);
      })
      .catch((error) => {
        remove();
        appLogout().then(() => {
          setGoto("/login");
        });
      });
  };
  const markAsUnread = (id) => {
    setContent(<LoadingSpinner />);
    let uri = "user/complain/" + id;
    const formData = {
      readed: null,
    };
    apiRequestUri(uri, "PUT", formData)
      .then((response) => {
        setContent(tableContent);
      })
      .catch((error) => {
        remove();
        appLogout().then(() => {
          setGoto("/login");
        });
      });
  };
  let action = {};

  action[t("Mark as Read")] = {
    callback: markAsRead,
  };

  action[t("Mark as Unread")] = {
    callback: markAsUnread,
  };

  const tableContent = (
    <Table
      title={t("User Complains")}
      mapping={TableMapping}
      uri={uri}
      action={action}
      user={customer}
    />
  );

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  const [content, setContent] = useState(tableContent);
  if (customer && customer.data && customer.data.role_name !== "admin") {
    setGoto("/admin");
  }

  if (!customer || !customer.data) {
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
  }

  return (
    <Container className="mt-0 mb-5 text-center mx-0 mw-100">
      {content}
    </Container>
  );
};

export default UserComplainTable;
